import React, { Component } from 'react'


export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    <span className="heading-meta">About Me</span>
                    <h2 className="colorlib-heading">Who Am I?</h2>
                    <p>I am a software enginner since 2017, I've been working on tech, from small projects until present on multiarea projects. Also I love cinema and I write some lectures to film. I develop mobile apps, backend, frontend, design BD and deploy everywhere.</p>
                    <p>I have started reflecting my ideas creating a community called "Code MX" where I teach and share tech knowledge, to me technology is a windows where everybody can walk and make a difference. </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">What I do?</span>
                <h2 className="colorlib-heading">Here are some of my expertise</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Frontend Development </h3>
                    <p>I have experience building websites using JS, HTML, CSS, React, Material UI and Boostrap.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Backend Development</h3>
                    <p>I like crete logic applications with Node/Express JS, Laravel/PHP, a little spring MVC.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Databases</h3>
                    <p>I've worked designing databases with MySQL and Mongo DB.</p>
                </div>
                </div>
            </div>
            
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Mobile Tech</h3>
                    <p>I usually create hibrids apps with React Native. But I like Native Android with Java/Kotlin.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Cloud Computing</h3>
                    <p>I've used and deployed projects with Azure, IBM Cloud, Heroku, Netlify.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Main/Trending Tech</h3>
                    <p>As a full stack developer. I use Git, gitflow, firebase, pusher, google maps. I've worked with Scrum and new tech like Blockchain.</p>
                </div>
                </div>
            </div>
           
            </div>
        </div>
        </section>
      </div>
    )
  }
}
